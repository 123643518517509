import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Vallarta Vamonos!';

  constructor(
    public translate: TranslateService,
    private titleService: Title,
    private metaService: Meta
    ){
      translate.addLangs(['English', 'Español']);
      translate.setDefaultLang('English');
      const browserLanguage = translate.getBrowserLang();
      translate.use(browserLanguage.match(/English|Español/) ? browserLanguage : 'English');
  }

  ngOnInit(){
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 
          'Vallarta, Puerto Vallarta, Transportation, Reliable, Safe, Good price, Service, Experience, Airport, Sayulita, Punta de Mita, Nuevo vallarta'
      },
      {name: 'description', content: 
        'Vallarta Vámonos provides efficient and effective transportation services to individual travelers, incentives for groups, travel agencies, corporations and families.'
      },
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
