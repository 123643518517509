import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Pickup } from './pickup'
import { PickupService } from './pickup.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pickup-form',
  templateUrl: './pickup-form.component.html',
  styleUrls: ['./pickup-form.component.css']
})
export class PickupFormComponent implements OnInit {

  @ViewChild('pickupForm', {static: false}) formValues;

  pickup = new Pickup;

  formArrival: Boolean = true;
  formDeparture: Boolean = true;
  btnOn: String = 'btn-primary';
  btnOff: String = 'btn-outline-primary';
  btnArrival: String = 'btn-outline-primary';
  btnBoth: String = 'btn-primary';
  btnDeparture: String = 'btn-outline-primary';

  name: String = '';

  constructor(
    private pickupService: PickupService,
    public translate: TranslateService
    ) { }

  ngOnInit() {
  }

  toggle(value): void {
    if (value === 'arrival'){
      this.formArrival = true
      this.formDeparture = false
      this.btnArrival = 'btn-primary'
      this.btnBoth = 'btn-outline-primary'
      this.btnDeparture = 'btn-outline-primary'
    }else if(value === 'both'){
      this.formArrival = true
      this.formDeparture = true
      this.btnArrival = 'btn-outline-primary'
      this.btnBoth = 'btn-primary'
      this.btnDeparture = 'btn-outline-primary'
    }
  }

  sendRequest(form: NgForm){
    if (this.validatePickup(this.pickup)){
      this.pickupService.sendPickup(form.value, this.translate.currentLang)
        .subscribe ( res => {
          Swal.fire("Your request has been sent");
        });
      this.formValues.reset();
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Your email missing or is invalid',
        footer: 'Please enter your email'
      })
    }
  }

  validatePickup = (form) => {
    if(form.email != null){
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(form.email).toLowerCase());
    }
  }

}
