import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pickup } from './pickup';

@Injectable({
  providedIn: 'root'
})
export class PickupService {
  //private pickupsUrl = "http://localhost:3001/pickups";
  private pickupsUrl = "https://acmb-vallartavamonos.herokuapp.com/pickups";

  constructor(
    private http: HttpClient
    ) { }

  getPickups(): Observable<Pickup[]> {
    return this.http.get<Pickup[]>(this.pickupsUrl);
  }

  sendPickup(pickup: Pickup, language) {
    pickup.language = language;
    return this.http.post(this.pickupsUrl, pickup);
  }

  wakeAPI() {
    return this.http.get(this.pickupsUrl);
  }
}
