import { timeout } from 'q';

export class Pickup {
    constructor(
        public id?: number,
        public name?: string, // name and lastname
        public sign_name?: string,
        public adults = 1,
        public kids = 0,
        public infants = 0,
        public email?: string,
        public phone?: string,

        public dateArrival?: string,
        public airlineArrival?: string, // airline flight and time
        public flightArrival?: string,
        public dateDeparture?: string, // from
        public language?: string,
        public pickup_time_departure?: string, // airline flight and time

        public time?: string,
        public destination?: string,
        public comments?: string
    ) {}
}
