import { Component, OnInit } from '@angular/core';
import { PickupService } from './pickup.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  formAbout: Boolean = false;
  formPickup: Boolean = true;

    constructor( private pickupService: PickupService ) { }

  ngOnInit() {
    console.log('sup');
    //Call the backend so heroku api will be on by the time of sending the email
    this.pickupService.wakeAPI().subscribe ( res => {
      console.log("Waking");
    });
  }

  toForm() {
    this.formAbout = false;
    this.formPickup = true;
  }
  toAbout() {
    this.formAbout = true;
    this.formPickup = false;
  }
}